<template>
  <div class="address-black-setting" v-loading="loading">
    <el-card class="box-card" shadow="hover">
      <template #header>
        <span class="card-header">黑名单自动设置</span>
      </template>

      <el-form :model="formData" :rules="rules" ref="formRef" label-width="0">
        <div class="setting-content">
          同一个地址经纬度
          <el-form-item prop="day_num">
            <el-input-number
              v-model="formData.day_num"
              :controls="false"
              :min="1"
              :precision="0"
              @change="handleSubmit"
              placeholder="请输入天数"
            />
          </el-form-item>
          天内，超过
          <el-form-item prop="order_num">
            <el-input-number
              v-model="formData.order_num"
              :controls="false"
              :min="1"
              :precision="0"
              @change="handleSubmit"
              placeholder="请输入订单数"
            />
          </el-form-item>
          笔订单，自动加入地址黑名单
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getInfo, update } from './api'
export default {
  name: 'AddressBlackSetting',
  data() {
    return {
      loading: false,
      formData: {
        day_num: 90,
        order_num: 5
      },
      rules: {
        day_num: [
          { required: true, message: '请输入天数', trigger: 'blur' },
          { type: 'number', min: 1, message: '天数必须大于0', trigger: 'blur' }
        ],
        order_num: [
          { required: true, message: '请输入订单数', trigger: 'blur' },
          { type: 'number', min: 1, message: '订单数必须大于0', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    async withLoading(fn) {
      try {
        this.loading = true
        return await fn()
      } finally {
        this.loading = false
      }
    },
    async getInfo() {
      await this.withLoading(async () => {
        const res = await getInfo()
        const { data } = res.data
        this.formData = data
      })
    },
    async handleSubmit() {
      await this.$refs.formRef.validate()
      await this.withLoading(async () => {
        await update(this.formData)
        this.$message.success('设置成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.address-black-setting {
  .box-card {
    margin: 20px;

    .card-header {
      font-weight: bold;
    }

    .setting-content {
      display: flex;
      align-items: center;

      .el-form-item {
        margin: 0 10px;
        display: inline-block;
      }
    }
  }
}
</style>
