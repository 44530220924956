import { createSetting } from '@shopex/finder'
import { batchOpDis, update } from '../api'
import { useDialog } from '@shopex/finder'

const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '提交', key: 'save', type: 'primary', size: 'default' }
]

const IS_NO_OPTIONS = [
  {label: '是', value: true},
  {label: '否', value: false}
]

const ADD_TYPE_OPTIONS = [
  {label: '手动', value: 'operators'},
  {label: '自动', value: 'auto'}
]

const RISK_OPTIONS = [
  {label: '开启', value: false},
  {label: '关闭', value: true}
]

export const tableSetting = (vm) => createSetting({
  search: [
    { name: '地址片段', key: 'content' },
    // {
    //   name: '经纬度加黑',
    //   key: 'has_coordinate',
    //   type: 'select',
    //   options: IS_NO_OPTIONS
    // },
    {
      name: '添加方式',
      key: 'create_type',
      type: 'select',
      options: ADD_TYPE_OPTIONS
    },
    {
      name: '风控状态',
      key: 'disabled',
      type: 'select',
      options: RISK_OPTIONS
    },
    {
      name: '添加时间',
      key: 'addTime',
      type: 'date-time-picker',
      valueFormat: 'timestamp',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
  ],
  columns: [
    { name: '地址片段关键词', key: 'content', minWidth: 200 },
    { name: '地址经度', key: 'lng', minWidth: 150 },
    { name: '地址纬度', key: 'lat', minWidth: 150 },
    {
      name: '风控状态',
      key: 'disabled',
      showType: 'switch',
      minWidth: 120,
      componentProps: {
        activeValue: false,
        inactiveValue: true,
        change: (v, row) => {
          update({ disabled: v, id: row.id }).then(res => {
            vm.$message.success('修改成功')
            vm.refresh()
          })
        }
      }
    },
    { name: '添加方式', key: 'create_type', minWidth: 120, formatter: (v) => ADD_TYPE_OPTIONS.find(f => f.value === v)?.label },
    { name: '添加时间', key: 'create_date', minWidth: 180 },
  ],
  actions: [
    {
      name: '添加地址黑名单',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async () => {
          useDialog(null, {
            title: `添加地址黑名单`,
            width: '30%',
            component: () => import('../detail.vue'),
            actions: commonActions
          }).then((args) => {
            if(args) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '导入',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportDialog()
        }
      }
    },
    {
      name: '导出',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.handleExport()
        }
      }
    },
    {
      name: '批量开启风控',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          if(!rows?.length) {
            vm.$message.warning('请选择')
            return
          }
          vm.$confirm('请确认是否进行该操作？', '提示').then(async () => {
            const ids = rows.map(v => v.id)
            await batchOpDis(ids, false)
            vm.$message.success('批量开启风控成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '批量关闭风控',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          if(!rows?.length) {
            vm.$message.warning('请选择')
            return
          }
          vm.$confirm('请确认是否进行该操作？', '提示').then(async () => {
            const ids = rows.map(v => v.id)
            await batchOpDis(ids, true)
            vm.$message.success('批量关闭风控成功')
            vm.refresh()
          })
        }
      }
    },
    // {
    //   name: '批量删除',
    //   slot: 'header',
    //   type: 'button',
    //   buttonType: 'primary',
    //   action: {
    //     handler: async (rows) => {
    //       if(!rows?.length) {
    //         vm.$message.warning('请选择')
    //         return
    //       }
    //       vm.$confirm('请确认是否批量删除？', '提示').then(async () => {
    //         const ids = rows.map(v => v.id)
    //         await batchDelete(ids)
    //         vm.$message.success('删除成功')
    //         vm.refresh()
    //       })
    //     }
    //   }
    // },
    // {
    //   name: '编辑',
    //   type: 'button',
    //   buttonType: 'text',
    //   action: {
    //     handler: async (row) => {
    //       const item = row[0]
    //       useDialog(null, {
    //         title: `编辑地址关键词`,
    //         width: '30%',
    //         detailData: {
    //           id: item.id,
    //           content: item.content,
    //           disabled: item.disabled
    //         },
    //         component: () => import('../detail.vue'),
    //         actions: commonActions
    //       }).then((args) => {
    //         if(args) {
    //           vm.refresh()
    //         }
    //       })
    //     }
    //   }
    // },
    // {
    //   name: '删除',
    //   type: 'button',
    //   buttonType: 'text',
    //   action: {
    //     handler: async (rows) => {
    //       vm.$confirm('此操作将删除该地址关键词, 是否继续?', '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //       }).then(async () => {
    //         await batchDelete(rows[0].id)
    //         vm.$message.success('删除成功')
    //         vm.refresh()
    //       })
    //     }
    //   }
    // },
    {
      name: '关联订单',
      type: 'button',
      buttonType: 'text',
      visible: (row) => row.lng && row.lat,
      action: {
        handler: async (rows) => {
          vm.$router.push({
            path: vm.matchInternalRoute('/order/entitytrade/tradenormalorders'),
            query: {
              lng: rows[0].lng,
              lat: rows[0].lat
            }
          })
        }
      }
    },
    {
      name: '查看日志',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: async (rows) => {
          useDialog(null, {
            title: `操作日志`,
            width: '30%',
            id: rows[0].id,
            component: () => import('../comp/op-log.vue'),
            actions: [
              { label: '取消', key: 'close', size: 'default' }
            ]
          })
        }
      }
    }
  ]
})
