<template>
  <div>
    <div>
      <el-row>
        <el-col :span="4">
          <el-input placeholder="请输入热词搜索" v-model="params.content" />
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="medium" @click="beforeSearch">搜索</el-button>
          <el-button type="primary" size="medium" @click="addAction">添加热词</el-button>
        </el-col>
      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="生效" name="running"></el-tab-pane>
        <el-tab-pane label="未生效" name="wait"></el-tab-pane>
        <el-tab-pane label="已失效" name="over"></el-tab-pane>
        <el-table :data="hotWordsList" :height="wheight-200" v-loading="loading">
          <el-table-column prop="id" label="热词ID" width="100"></el-table-column>
          <el-table-column prop="content" label="热词名称" min-width="180"></el-table-column>
          <el-table-column prop="path" label="路径" min-width="150">
            <template slot-scope="scope">
              {{ JSON.parse(scope.row.path) ? getLabelName(JSON.parse(scope.row.path)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="选产品启用" min-width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.chose_goods_is_show" @change="handleIsShowChange(scope.row, 'chose_goods_is_show')" />
            </template>
          </el-table-column>
          <el-table-column label="默认状态" min-width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_default"
                @change="handleIsShowChange(scope.row, 'is_default')"
              />
            </template>
          </el-table-column>
          <el-table-column label="展示状态" min-width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show"
                @change="handleIsShowChange(scope.row, 'is_show')"
              />
            </template>
          </el-table-column>
          <el-table-column label="展示排序" min-width="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.sort" @change="handleIsShowChange(scope.row, 'sort' )" ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="联想搜索词" min-width="200">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.rel" :key="index">{{ item.content + '、' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="editAction(scope.row)">编辑</el-button>
              <el-button type="text"  @click="delAction(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[10,20,50]"
            :current-page.sync="params.page"
            :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%" @close="closeChange" :close-on-click-modal="false">
      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" label-width="100px">
        <el-form-item label="热词名称" prop="content">
          <el-input v-model="dialogForm.content" maxlength="20" placeholder="请输入热词名称" show-word-limit style="width: 55%;"></el-input>
        </el-form-item>
        <el-form-item label="搜索联想词">
          <div class="associational_word">
            <el-tag
              v-for="tags in associational_word"
              :key="tags"
              closable
              style="margin-right: 10px;"
              @close="delTagsChange(tags)"
            >
              {{tags}}
            </el-tag>
            <el-input
              v-model="inputValue"
              style="width: 25%;"
              placeholder="请输入联想词"
            >
            </el-input>
            <i slot="suffix" class="el-input__icon el-icon-circle-plus-outline" style="color:#1989fa;font-size: 18px;" @click="addHotWordChange"></i>
          </div>
        </el-form-item>
        <el-form-item label="路径设置" required>
          <div v-if="localValue.data && localValue.data.length > 0">
            <el-tag @click="onPickChange" v-for="(item,index) in localValue.data" :key="item.id" closable @close="delPathTagsChange(index)">{{ getLabelName(item) }}</el-tag>
          </div>
          <div v-else>
            <el-tag @click="onPickChange">{{ getLabelName(this.localValue) }}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="有效期" required>
          <el-date-picker @change="changeDatePicker" :disabled="dialogForm.is_forever" v-model="dateRange" start-placeholder="开始日期" end-placeholder="结束日期"
            type="datetimerange" range-separator="至" value-format="timestamp"
            :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="dialogForm.is_forever" @change="changeIsForever">永久有效</el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer content-center">
        <el-button @click="closeChange">取 消</el-button>
        <el-button type="primary" @click="addChange('dialogForm')">确 定</el-button>
      </div>
    </el-dialog>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getPreferential, seckillActivityUpdateStatus } from '@/api/promotions'
import { getKeywords, postKeywords, delKeywords, editKeywords } from '@/api/shopScreen'
import { getDistributorList } from '@/api/marketing'
import { LINK_PATH } from '@/consts'
export default {
  props: ['getStatus'],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      activeName: 'all',
      hotWordsList: [],
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
        content: '',
      },
      dialogVisible: false,
      dialogTitle: '',
      dialogForm: {
        content: '',
        rel: [],
        is_forever: false,
        start_time: '',
        end_time: ''
      },
      dialogRules: {
        content: [
          { required: true, message: '请输入热词名称', trigger: 'blur' }
        ]
      },
      inputValue: '',
      localValue: {},
      associational_word: [],
      dateRange: '',
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'wxapp_id',
      'template_name'
    ])
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
      if (tab.name) {
        this.params.status = tab.name
      }
      this.params.page = 1
      this.getHotWordsList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getHotWordsList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getHotWordsList()
    },
    addAction () {
      this.dialogTitle = '热词新增'
      this.dialogVisible = true
    },
    editAction (row) {
      this.dialogTitle = '热词编辑'
      this.dialogVisible = true
      this.dialogForm = {
        id: row.id,
        content: row.content,
        is_forever: row.is_forever,
        start_time: row.start_time,
        end_time: row.end_time
      }
      if (row.start_time != '0') {
        this.dateRange = [row.start_time * 1000, row.end_time * 1000]
      }
      this.localValue = JSON.parse(row.path) || {}
      this.associational_word = row.rel.map(item => item.content)
    },
    delAction (row) {
      console.log('del-row: ', row);
      let that = this
      this.$confirm('此操作将删除所选热词, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        delKeywords(row.id).then((response) => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 2 * 1000
          });
          loading.close()
          that.getHotWordsList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    beforeSearch() {
      this.params.page = 1
      this.getHotWordsList()
    },
    getHotWordsList () {
      this.loading = true
      getKeywords(this.params).then(response => {
        this.hotWordsList = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    handleIsShowChange (row, type)  {
      let param = {
        id: row.id,
        [type]: row[type],
      }
      editKeywords(param).then(response => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.getHotWordsList()
      })
    },
    editItemsSort (index, row) {
      // var reg = /(^[1-9]?$)/
      // if (row.items_arr.sort == '') {
      //   this.$message.error('不能为空');
      //     return;
      //   // callback(new Error('不能为空'))
      // } else if (!reg.test(row.items_arr.sort)) {
      //   this.$message.error('必须为1-9之间的数字');
      //     return;
      //   // callback(new Error('必须为1-9之间的数字'))
      // }

      // let param = {
      //       id: row.id,
      //       type: row.type,
      //       items: row.items_arr,
      //     }

      //   updatePreferential (row.id, param).then(response => {
      //   const that = this
      //   this.$message({
      //     message: '修改成功',
      //     type: 'success',
      //     duration: 3 * 1000,
      //     onClose () {
      //       // this.closeDialogAction1()
      //       that.closeDialog()
      //       that.beforeSearch()
      //       // this.$router.go(-1)
      //     }
      //   })
      // })
    },
    addHotWordChange () {
      if (!this.inputValue) return
      console.log(this.dialogForm)
      const find = this.associational_word.find(item => item === this.inputValue)
      console.log(this.inputValue, find)
      if (find) {
        this.$message.error('不能重复添加')
        return
      }
      this.associational_word.push(this.inputValue)
      this.inputValue = ''
    },
    addChange (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.localValue.id && !this.localValue.data) {
            this.$message.error('请设置路径')
            return
          }
          if (!this.dialogForm.is_forever && (!this.dialogForm.start_time || this.dialogForm.start_time == '0')) {
            this.$message.error('请设置有效期')
            return
          }
          if (this.inputValue) {
            this.associational_word.push(this.inputValue)
          }
          const params = {
            ...this.dialogForm,
            path: JSON.stringify(this.localValue),
            rel: this.associational_word
          }
          // 如果没有id，则表示为新增
          if (!this.dialogForm.id) {
            postKeywords(params).then((res) => {
              this.$message({ type: 'success', message: '操作成功' })
              this.params.page = 1
              this.getHotWordsList()
            })
          } else {
            postKeywords(params).then((res) => {
              this.$message({ type: 'success', message: '操作成功' })
              this.getHotWordsList()
            })
          }
          this.closeChange()
          // addPagesTemplate(this.dialogForm).then(res => {
          //   this.getList()
          //   this.resetForm(formName)
          // })
        }
      })
    },
    closeChange () {
      this.inputValue = ''
      this.dialogTitle = ''
      this.dialogForm = {}
      this.dialogVisible = false
      this.localValue = {}
      this.associational_word = []
      this.dateRange = ''

      this.dialogForm = {
        content: '',
        rel: [],
        is_forever: false,
        start_time: '0',
        end_time: '0'
      }
    },
    async onPickChange() {
      const { linkPage, id, title, goodsNo, data, goodsId, items } = this.localValue
      let idList = []
      let linkPageType = linkPage == 'goods'
      if (linkPageType) {
        if (data) {
          data.forEach(element => {
            idList.push({
              item_id: element.id,
              title: element.title,
              goodsNo: element.goodsNo,
              goods_id: element.goodsId,
            })
          })
        } else {
          // 兼容老活动已选择的商品
          idList.push({
            item_id: id,
            title: title,
            goodsNo: goodsNo,
            goods_id: goodsId,
          })
        }
      }
      const res = await this.$picker.path({
        data: linkPageType ? idList : goodsId,
        tab: linkPage,
        multiple: false,
        wxapp_id: this.wxapp_id,
        template_name: this.template_name,
        isDouyin: false,
        isHotWords: true,
        pathLink: 'keywords',
        num: 50,
        paramsFieldExclude: ['distributor_id'],
        queryParams: {
          is_sku: false,
        },
        rowKey: 'goods_id',
      })
      if (res.length > 1) {
        this.localValue = {
          linkPage: res[0].linkPage,
          linkType: 0,
          linkUrl: '',
          video_cover: '',
          CopyUrl: "",
          CopyTitle: "",
          autoplay: false,
          hotzoneType: 'link',
          data: res
        }
      } else {
        this.localValue = {
          id: res.id,
          title: res.title,
          goodsNo: res.goodsNo,
          goodsId: res.goodsId,
          linkPage: res.linkPage,
          linkType: 0,
          linkUrl: '',
          video_cover: '',
          CopyUrl: "",
          CopyTitle: "",
          autoplay: false,
          hotzoneType: 'link'
        }
      }
    },
    getLabelName(value) {
      console.log(value, 'value')
      const { linkPage, title, id, data } = value
      if (data) {
        return `已选择${data.length}个${LINK_PATH[linkPage]}`
      }else if (linkPage) {
        return `${LINK_PATH[linkPage]}：${id ? '[' + id + ']' : ''} ${title}`
      } else {
        return '选择路径'
      }
    },
    delTagsChange (tags) {
      console.log(tags)
      const index = this.associational_word.findIndex(item => item === tags)
      this.associational_word.splice(index, 1)
    },
    changeDatePicker() {
      if (this.dateRange && this.dateRange.length > 0) {
        this.dialogForm.start_time = this.dateRange[0] / 1000
        this.dialogForm.end_time = this.dateRange[1] / 1000
      } else {
        this.dialogForm.start_time = '0'
        this.dialogForm.end_time = '0'
      }
    },
    delPathTagsChange(i) {
      let arr = [...this.localValue.data]
      arr.splice(i, 1)
      this.localValue.data = arr
      if (arr.length == 1) {
        let obg = { ...this.localValue }
        delete obg.data
        this.localValue = {...obg, ...arr[0]}
      }
    },
    changeIsForever(value) {
      if (value) {
        this.dateRange = ''
        this.dialogForm.start_time = '0'
        this.dialogForm.end_time = '0'
      }
    }
  },
  mounted () {
    this.getHotWordsList()
  },
  watch: {
    getStatus (val) {
      if (val) {
        this.getHotWordsList()
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
