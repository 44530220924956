<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      :hooks="{
        beforeSearch
      }"
    />
    <ImportDialog
      ref="importDialog"
      v-show="false"
      buttonName="导入"
      buttonSize="medium"
      fileName="导入地址片段关键词"
      fileType="black_address"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
import { List, handleExport } from './api'
import ImportDialog from '@/components/importDialog'

export default {
  components: {
    ImportDialog
  },
  data () {
    return {
      table_url: List
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {},
  methods: {
    openImportDialog() {
      this.$refs.importDialog.visible = true
    },
    refresh() {
      this.$refs.finder.refresh()
    },
    handleExport() {
      const searchparams = this.$refs.finder.getSearchParams()
      const selection = this.$refs.finder.$refs.finderTable.getSelection()
      let params = {
        ...searchparams,
        id: selection?.map(item => item.id),
        is_export: 1
      }
      handleExport(params).then(_ => {
        this.$message.success('导出成功，请前往导出列表查看')
      })
    },
    beforeSearch(params) {
      const start_time = params.addTime?.[0] && params.addTime?.[0] / 1000
      const end_time = params.addTime?.[1] && params.addTime?.[1] / 1000
      delete params.addTime
      return {
        ...params,
        start_time,
        end_time
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}

</style>
