import fetch from '@/utils/fetch'

export function getInfo () {
  return fetch({
    url: '/secure/black_address/setting/info',
    method: 'get'
  })
}

export function update (data) {
  return fetch({
	  url: '/secure/black_address/setting/update',
	  method: 'post',
	  data
  })
}
